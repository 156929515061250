<template>
	<section id="home-section" class="bg-vh">
        <div class="jumbotron jumbotron-fluid hero-img " >
            <div class="container hero-text" >
				<h1>{{ $t('hometitle') }}</h1>
				<p class=' mx-auto' v-html="$t('hometext')"></p>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'HomePage',
    props: {
        
    },
    components: {

    },
    data () {
        return {
            homedata: [],
            backImg: './assets/images/bg.jpg'
        }
    },
    methods: {

    },
    mounted() {
		document.querySelector(".thisfooter").classList.remove("fixitfooter");
    }
}
</script>

<style >
.bg-vh {
    height: 90vh;
    max-width: 100%;
}

.jumbotron {
    background-size: cover;
    background-repeat: no-repeat;
    background-position:center;
    width:100%;
    height:100%;
    max-width:100%; 
}

.hero-img {
    background-image: linear-gradient(rgba(100, 100, 100, 0.5), rgba(0, 0, 0, 0.8)), url(../assets/images/bg.jpg); 
    height:100vh;
}

.hero-text {
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;  
}
.hero-text h1 {
    color:#4E6009;
}
.hero-text p {
    color:#EAEAEA;
}
.hero-text .btn {
    background-color:#2A2E11;
    border-color: #4E6009;
}

@media only screen and (max-width:575px)
{
    .home-section
    {
        height:auto;
    }
}
</style>
