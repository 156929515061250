export default {
  "hometitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kittichet Puengreang"])},
  "hometext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Graduated From THE FACULTY OF TECHNOLOGY AND ENVIRONMENT INFORMATION TECHNOLOGY BACHELOR OF SCIENCE <br>Develop Backend Website With PHP - Framework CI And Laravel<br>Node.js - Framework Express<br>Database Mysql, MongoDB <br>Frontend With Html, Css, Javascript, jquery, Vue.js, react"])},
  "about_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Experience"])},
  "serene_pos_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WEB AND APPLICATION DEVELOPER"])},
  "serene_pos_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["At Serene Property And Development Co., LTD.<br>Febuary 2019 - July 2024<br><b>Responsibilities</b><br>Develop Website and Application With PHP And use External APIs such as Line OA, SCB Developer"])},
  "gh_pos_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WEB AND UNITY DEVELOPER"])},
  "gh_pos_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["At Groundhog studio<br>July 2015 - Febuary 2019<br><b>Responsibilities</b><br>Manage Website (PHP), Manage API connect with Application Unity with C# and Publish IOS and Android Application to Store"])},
  "webconnection_pos_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WEB DEVELOPER"])},
  "webconnection_pos_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["At Webconnection<br>March 2014 - March 2015<br><b>Responsibilities</b><br>Manage Hotel Website"])},
  "graduated_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Graduated"])},
  "graduated_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prince Songkla University Phuket Campus<br>2010 - 2014<br>Bachelor's degree <br>THE FACULTY OF TECHNOLOGY AND ENVIRONMENT - INFORMATION TECHNOLOGY (IT) GPA 3.78"])},
  "serenewebsite_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serene Website"])},
  "serenewebsite_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<b>Responsibilities</b> <br>- Create Website From Webdesign<br>- Manage Backend System<br>- Manage Email System<br><br><b>tech</b><br>PHP(CI3) Html CSS JS Jquery"])},
  "sereneprojectwebsite_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serene Project Website"])},
  "sereneprojectwebsite_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<b>Responsibilities</b> <br>- Create Website From Webdesign<br>- Manage Backend System<br>- Manage 360 Picture in Website<br>- Manage Email System<br><br><b>tech</b><br>PHP(CI3) Html CSS JS Jquery 360 Library"])},
  "akirawebsite_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Akira Resident Website"])},
  "akirawebsite_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<b>Responsibilities</b> <br>- Edit Webdesign From Source<br><br>"])},
  "spmwebsite_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monthly billing system"])},
  "spmwebsite_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<b>Responsibilities</b> <br>- Manage Reservation System<br>- Manage Bill System<br>"])},
  "itticket_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IT Repair Request System"])},
  "itticket_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<b>Responsibilities</b> <br>- Manage Repair Request System<br><br><br>"])},
  "pluscondoconnect_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plus Condo Connect"])},
  "pluscondoconnect_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<b>Responsibilities</b> <br>- Use to view the contract of renting a condo<br>- Use to pay from QR Code / SCB Easy by SCB and credit card by Omise.<br>- View Appointments<br>- View Construction Progress<br>- View Contract<br>"])},
  "assetsweb_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assets system"])},
  "assetsweb_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<b>Responsibilities</b> <br>- Manage Assets system<br>- Manage QRCode for check assets<br>- Manage Report<br><br>"])},
  "appealweb_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Complaint System"])},
  "appealweb_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<b>Responsibilities</b> <br>- Manage Complaint System<br>- Send secret email<br><br>"])},
  "rmsweb_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sale System"])},
  "rmsweb_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<b>Responsibilities</b> <br>- Manage additional / modify various modules that have been assigned<br>- Fix Bug<br><br>"])},
  "fmweb_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facilities Management System"])},
  "fmweb_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<b>Responsibilities</b> <br>- Manage additional / modify modules that have been assigned<br>- Fix Bug<br><br>"])},
  "authen_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2 Authentication"])},
  "authen_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<b>Responsibilities</b> <br>- Add Authentication From Line OA to User Line<br>"])},
  "vrboothweb_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VR Booth Online"])},
  "vrboothweb_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<b>Responsibilities</b> <br>- Manage additional / modify modules that have been assigned<br><br><br>"])},
  "warnweb_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daily reminder / Backup"])},
  "warnweb_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<b>Responsibilities</b> <br>- Create Cronjob Notification<br>- Create Backup<br><br>"])},
  "questionnaireweb_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daily reminder / Backup"])},
  "questionnaireweb_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<b>Responsibilities</b> <br>- Manage PWA questionnaire Website use on Ipad<br><br>"])},
  "followrunner_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Followrunner"])},
  "followrunner_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<b>Responsibilities</b> <br>- Manage Frontend and Backend <br>- Mamage Sales System  <br>- Manage Register System <br>- Manage Payment System (Paypal)<br><br><br><br>"])},
  "krabiweb_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Development Project Coordination Center Krabi"])},
  "krabiweb_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<b>Responsibilities</b> <br>- Develop Website<br><br><br><br><br>"])},
  "eyetestweb_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eyestesting Bangkok Hospital Phuket"])},
  "eyetestweb_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<b>Responsibilities</b> <br>- Manage Questionair System<br>- Manage Color Blindness  System<br>- Manage Information Wensite<br>- Manage Report<br><br>"])},
  "localfoodweb_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PHUKET LOCAL FOOD"])},
  "localfoodweb_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<b>Responsibilities</b> <br>- Manage Information Wensite<br>- Manage API for Application<br><br><br><br><br><br><br><br>"])},
  "panyaandamanweb_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Panya Andaman"])},
  "panyaandamanweb_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<b>Responsibilities</b> <br>- Modify Website<br>- Manage Video and Sound Backend<br><br><br><br><br><br><br><br>"])},
  "jdpoolsweb_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["JDPOOLSWEBSERVICE"])},
  "jdpoolsweb_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<b>Responsibilities</b> <br>- Manage Sale System<br>- Manage Report<br>- Manage Import/Export tools price from excel<br>- Export PDF<br>- Manage report weekly and send by email<br><br><br><br><br>"])},
  "cracycat_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cracycat"])},
  "cracycat_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<b>Responsibilities</b> <br>- Manage Online business card<br>- Manage PDF Online System<br><br><br>"])},
  "canvaskids_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CanvasKids"])},
  "canvaskids_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<b>Responsibilities</b> <br>- Manage Website Canvas Kids<br>- Manage Application Canvas Kids<br>- Manage API For Application<br>"])},
  "ghweb_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groundhog Studio"])},
  "ghweb_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<b>Responsibilities</b> <br>- Manage Parallax Website<br>- Manage Website Portfolio<br><br><br>"])},
  "bpkarapp_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BPKAR APPLICATION"])},
  "bpkarapp_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<b>Responsibilities</b> <br>- Manage Take Photo System And Upload to server<br>- Manage Share Facebook<br>- Publish App to IOS and Android Store <br><br><br>"])},
  "vrkrabiapp_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["APPLICATION VRKRABI"])},
  "vrkrabiapp_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<b>Responsibilities</b> <br>- Manage API <br>- Publish App to IOS and Android Store <br><br><br><br>"])},
  "canvaskidsapp_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["APPLICATION CANVAS KIDS"])},
  "canvaskidsapp_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<b>Responsibilities</b> <br>- Manage In App Purchase <br>- Manage API <br>- Publish App to IOS and Android Store <br><br><br>"])},
  "smartandamanapp_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SMART ANDAMAN APPLICATION"])},
  "smartandamanapp_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<b>Responsibilities</b> <br>- Manage Gesture System <br>- Manage Picture Gallery <br>- Manage Data in Application <br><br><br>"])},
  "keehinweb_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keehin"])},
  "keehinweb_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<b>Responsibilities</b> <br>- Manage Website<br>- Manage Backend<br>- Manage SEO<br><br><br>"])},
  "otherweb_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other"])},
  "otherweb_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<b>Responsibilities</b> <br>- Manage Website/Module from Requirement<br><br><br>"])}
}