<template>
  <section id="blog-section">
    <div class="jumbotron jumbotron-fluid bg2 m-0 pt-5">
      <div class="container">
        <div class="col-12 text-center pt-5">
          <h1>Portfolio</h1>
          <hr />
          <h2 v-if="loading_main" class="loading"><center>Loading Data...</center></h2>
        </div>
        <div class="maincontent">
          <div class="row project_container" v-bind:id="category.name" v-for="(category, index) in categorys" :key="category._id">
           
            <div class="col-12 text-center">
              <h3 class="project_header">{{ category.name }}</h3>
              <center><hr class="sethr" /></center>
            </div>
            <h2 v-if="loadings[index]" class="loading"><center>Loading Data...</center></h2>
						<PortfolioSegment :cat="category._id" :index="index" @changeload="ChangeL($event)" />
          </div>
        </div>
        <div class="col-12 text-center"><br /><br /></div>
      </div>
      <!--END Containner-->
    </div>
    <!--END Jumbotron-->
  </section>
  <!--END section block-->
  <!-- End blog section -->
</template>

<script>
import PortfolioSegment from '../components/PortfolioSegment'

export default {
  name: "PortfolioPage",
  props: {},
  components: {
		PortfolioSegment
	},
  data() {
    return {
      loading_main: true,
      categorys_div: "",
      portdatas_div: [],
      categorys: [],
      loadings: [],
      portdatas: [],
      portdata: {
        _id: "",
        name: "",
        link: "",
        images: "",
        content: "",
        technology: "",
        api: "",
        category: "",
        order_show: "",
        discard: "",
        dateTimeCreated: "",
        dateTimeUpdated: "",
      },
      portdata_id: "",
			category: {
        _id: "",
        name: "",
        order_show: "",
        discard: "",
        dateTimeCreated: "",
        dateTimeUpdated: "",
      },
      category_id: "",
      port_url: process.env.VUE_APP_DATA_URL + "portfolio",
      cat_url: process.env.VUE_APP_DATA_URL + "category",
    };
  },
  mounted() {
    document.querySelector(".thisfooter").classList.add("fixitfooter");
    this.fetchData();
  },
  methods: {
    fetchData() {
      // console.log(this.cat_url);
      fetch(this.cat_url)
        .then((res) => res.json())
        .then((res) => {
          if (res.success == 1) {
            this.categorys = res.return;
            for (let index = 0; index < this.categorys.length; index++) {
              this.loadings[index] = true;
            }
            this.loading_main = false;
          } else {
            alert("Failed");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    fetchCatData() {
      fetch(this.cat_url)
        .then((res) => res.json())
        .then((res) => {
          if (res.success == 1) {
            this.categorys = res.return;

          } else {
            alert("Failed");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    fetchPortData() {
      fetch(this.port_url)
        .then((res) => res.json())
        .then((res) => {
          if (res.success == 1) {
            this.portdatas = res.return;
 
          } else {
            alert("Failed");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    ChangeL(index) {
      this.loadings[index] = false;
    },
  },
  computed: {},
  watch: {
    "$i18n.locale": function (newVal, oldVal) {
      // console.log(newVal + oldVal);
      Array.from(document.querySelectorAll("." + oldVal + "_data")).forEach(
        (sh) => {
          sh.style.display = "none";
        }
      );
      Array.from(document.querySelectorAll("." + newVal + "_data")).forEach(
        (sh) => {
          sh.style.display = "block";
        }
      );
    },
  },
};
</script>
<style>
.bg2 {
  background: rgb(134, 180, 26);
  background: linear-gradient(
    0deg,
    rgba(134, 180, 26, 1) 0%,
    rgba(34, 193, 195, 1) 100%
  );
}

.project_container {
  /* margin-top: 100px; */
  padding-top: 80px;
  padding-bottom: 100px;
  border-bottom: 1px solid black;
}

.sethr {
  width: 100px;
  height: 20px;
  border-width: 5px;
  color: green;
  text-align: center;
  margin-bottom: 50px;
}

/*================================================= */
/*   Blog Section
	*================================================= */
.blog-text {
  background: rgb(221, 197, 200);
  background: radial-gradient(
    circle,
    rgba(221, 197, 200, 1) 87%,
    rgba(173, 76, 76, 1) 100%
  );
  min-height: 650px;
}

.blog-text h6 {
  color: #2a2e11;
}

.blog-text p {
  color: #4e6009;
}

.blog-text .btn {
  background-color: #2a2e11;
  border-color: #4e6009;
}

.blog-text .iconbg-fb {
  color: #3b5998;
}

.blog-text .iconbg-tt {
  color: #5bc0de;
}

.blog-text .iconbg-gplus {
  color: #d9534f;
}

.blog-text .iconbg-in {
  color: #0077b5;
}

.en_data {
  display: none;
}
</style>