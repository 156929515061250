<template>
    <nav class="navbar navbar-expand-lg navbar-dark bg-dark">
        <div class="container">
            <a class="navbar-brand" href="">{{msg}}</a>
            <!-- <a href="/"><img src="https://phuketdeveloper.com/kp_images/th_flag.png"></a>&nbsp;
			<a href="/en"><img src="https://phuketdeveloper.com/kp_images/en_flag.png"></a> -->
              <LocaleSwitcher />
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarTogglerDemo02" aria-controls="navbarTogglerDemo02" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarTogglerDemo02">
                <ul class="navbar-nav ms-auto">
                    <li class="nav-item" @click="transitionEnter()">
                        <router-link to="/" class="nav-link">Home</router-link>
                    </li>
                    <li class="nav-item" @click="transitionEnter()">
                        <router-link to="/about" class="nav-link">About</router-link>
                    </li>
                    <li class="nav-item" @click="transitionEnter()">
                        <router-link to="/portfolio" class="nav-link">Portfolio</router-link>
                    </li>
                    <li class="nav-item" @click="transitionEnter()">
                        <router-link to="/contact" class="nav-link">Contact</router-link>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
</template>

<script>
import LocaleSwitcher from "./LocaleSwitcher.vue";

export default {
    name: 'NavItem',
    props: {
        msg: String
    },
    components: { LocaleSwitcher },
    methods: {
        transitionEnter()
        {
            document.querySelector("body").classList.add("hiddenoverflow");
            setTimeout(function(){
                document.querySelector("body").classList.remove("hiddenoverflow");
            }, 500);
        },
    }
}
</script>
<style>
    .navbar
    {
        position: fixed !important;
        top:0 !important;
        width: 100%;
        z-index: 9999;
    }
</style>