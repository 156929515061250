<template>
	<!-- <select v-model="$i18n.locale" >
		<option v-for="(locale, i) in locales" :key="`locale-${i}`" :value="locale" :class="'flag_'+locale">
			{{ locale }}
		</option>
	</select> -->
	<div>
		<img v-for="(locale, i) in locales" :key="`locale-${i}`" 
		@click="changeLocale(locale)" :src="'https://phuketdeveloper.com/kp_images/'+locale+'_flag.png'" 
		:class="[{ 'flag_selectd': (locale === activeId) },'flag_select','flag_'+locale]"  />
	</div>
</template>

<script>

export default {
	name: "LocaleSwitcher",
	data() {
		return { locales: ["th", "en"], activeId:'en' };
	},
	methods: {
		changeLocale: function (locale) {              
			this.activeId = locale;
			this.$i18n.locale = locale;
        }
	}
};
</script>

<style scoped>
	.flag_selectd
	{
		padding:5px;
		border: 1px solid red;
	}
	.flag_select
	{
		cursor: pointer;
		margin-right:10px;
	}
</style>