<template>
	<section id="contact-section">
        <div class="jumbotron jumbotron-fluid bg-1 m-0">
			<div class="container  hero-text">
                <div class="row">
                    <div class="col-12 text-center ">
                        <h2 class="headtxt">Contact</h2>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 text-center ">
                        <center><hr class="sethr"></center>
                    </div>
                </div>
                <div class="row ">
                    <div class="col-12">
                        <div class="card ">
                            <div class="card-body">
                                <div class="social_container m-3    ">
                                    <font-awesome-icon icon="fa-solid fa-mobile" class="me-1" /> : <a href="tel:+66894705387">0894705387</a>
                                </div>
                                <div class="social_container m-3">
                                    <font-awesome-icon icon="fa-solid fa-envelope" /> : <a href="mailto:p.kittichet@gmail.com">p.kittichet@gmail.com</a>
                                </div>
                                <div class="social_container m-3">
                                    <font-awesome-icon icon="fa-brands fa-facebook" /> : <a target="_blank" href="https://www.facebook.com/deathwing.tei">Facebook</a>
                                </div>
                                <div class="social_container m-3">
                                    <font-awesome-icon icon="fab fa-linkedin-in" /> : <a target="_blank" href="https://www.linkedin.com/in/kittichet-Puengreang-b7832989/">Linkin</a>
                                </div>
                                <div class="social_container m-3">
                                    <font-awesome-icon icon="fa-brands fa-line" /> : <a target="_blank" href="https://line.me/ti/p/I_QfAkbbz4?fbclid=IwAR0Bdk3rlDeAQgZjzc8OoDYoGJSHbuLjEjJbzo2i5yMKuswaSCZbaktekT0">Line</a>
                                </div>
                                <div class="social_container m-3">
                                    <font-awesome-icon icon="fa-brands fa-github" /> : <a target="_blank" href="https://github.com/deathwingtei">GitHub</a>
                                </div>
                                <div class="social_container m-3">
                                    <font-awesome-icon icon="fa-brands fa-bandcamp" /> : <a target="_blank" href="https://kittichet.devuweb.com/html/cer.html">Certificate</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
			</div>
		</div>
	</section>
</template>

<script>
export default {
    name: 'ContactPage',
    props: {
        
    },
    components: {

    },
    data () {
        return {
        homedata: []
        }
    },
    methods: {

    },
    mounted() {
		document.querySelector(".thisfooter").classList.remove("fixitfooter");
    }
}
</script>

<style scoped>
    .bg-1
    {
        background: rgb(5,255,0);
        background: linear-gradient(90deg, rgba(5,255,0,1) 0%, rgba(24,100,3,1) 100%);
    }
    .hero-text {
        text-align: center;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: white;  
    }
    .hero-text h2 {
        color:#fff;
        font-size:60px;
    }
    .hero-text p {
        color:#EAEAEA;
    }
    .hero-text .btn {
        background-color:#2A2E11;
        border-color: #4E6009;
    }
    .sethr
    {
        width: 80px;
        height: 20px;
        border-width: 5px;
        color:red;
        text-align: center;
    }
    #contact-section
    {
        height: 100vh;
    }
    .card {
        margin: 0 auto; /* Added */
        float: none; /* Added */
        margin-bottom: 10px; /* Added */
        width: 50vh;
        text-align: left;
    }
    .card-body
    {
        color:black;
    }
    
</style>
