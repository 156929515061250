<template>
  <div class="row" >
      <div class="col-md-4 col-12 my-3" v-for="portdata in portdatas" :key="portdata._id">
        <div class="blog-text p-3">
          <div class="my-3 text-center">
            <img :src="portdata.images" class="img-fluid img-thumbnail" />
          </div>

          <h6 class="m-0 p-0 th_data">{{ portdata.name.th }}</h6>

          <h6 class="m-0 p-0 en_data">{{ portdata.name.en }}</h6>

          <p class="small my-3 th_data" v-if="portdata.link.th != ''" v-html="portdata.link.th"></p>

          <p class="small my-3 en_data" v-if="portdata.link.en != ''" v-html="portdata.link.en"></p>

          <p class="small my-3 th_data" v-if="portdata.content.th != ''" v-html="'หน้าที่/ความรับผิดชอบ:<br />'+portdata.content.th"></p>

          <p class="small my-3 en_data" v-if="portdata.content.en != ''" v-html="'Responsibility:<br />'+portdata.content.en"></p>

          <p class="small my-3 th_data" v-if="portdata.technology.th != ''" >Tech: {{ portdata.technology.th }}</p>

          <p class="small my-3 en_data"  v-if="portdata.technology.en != ''">Tech: {{ portdata.technology.en }}</p>

          <p class="small my-3 th_data"  v-if="portdata.api.th != ''">API ภายนอก: {{ portdata.api.th }}</p>

          <p class="small my-3 en_data"  v-if="portdata.api.en != ''">External API: {{ portdata.api.en }}</p>
        </div>
      </div>
    </div>

</template>
  
<script>
// https://portfolioapi.devuweb.com/api/category_portfolio/:id
export default {
  name: "PortfolioSegment",
  props: {
    cat: String,
    index: Number,
  },
  data() {
    return {
      portdatas: [],
      portdata: {
        _id: "",
        name: "",
        link: "",
        images: "",
        content: "",
        technology: "",
        api: "",
        category: "",
        order_show: "",
        discard: "",
        dateTimeCreated: "",
        dateTimeUpdated: "",
      },
      port_cat_url:
        process.env.VUE_APP_DATA_URL + "category_portfolio/" + this.cat,
    };
  },
  mounted() {
    // document.querySelector(".thisfooter").classList.add("fixitfooter");

    this.fetchData();
  },
  methods: {
    fetchData() {
      fetch(this.port_cat_url)
        .then((res) => res.json())
        .then((res) => {
          this.portdatas = res.return;
          this.$emit("changeload", this.index);
        });
    },
  },
  watch: {
    "$i18n.locale": function (newVal, oldVal) {
      // console.log(newVal + oldVal);
      Array.from(document.querySelectorAll("." + oldVal + "_data")).forEach(
        (sh) => {
          sh.style.display = "none";
        }
      );
      Array.from(document.querySelectorAll("." + newVal + "_data")).forEach(
        (sh) => {
          sh.style.display = "block";
        }
      );
    },
  },
};
</script>