<template>
  <div>
    <NavItem msg="Kittichet Portfolio"/>
      <router-view v-slot="{ Component }">
        <transition name="slide-fade" >
          <component :is="Component" />
        </transition>
      </router-view>
    <FooterItem/>
  </div>
</template>

<script>
import NavItem from './components/Nav.vue'
import FooterItem from './components/Footer.vue'

export default {
  name: 'App',
  components: {
    NavItem,
    FooterItem
  }
}
</script>

<style >
@import url('https://fonts.googleapis.com/css2?family=Kanit:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
*{
  box-sizing: border-box;
  margin:0;
  padding:0;
}

button{
  cursor: pointer;
}

body{
  font-family: 'Kanit',sans-serif !important;

}

.router-link-active
{
    color: #fff !important;
}

/* footer section */

.fixitfooter
{
  position: inherit;
}


.fade-enter-active, .fade-leave-active {
  transition-property: opacity;
  transition: all 0.3s ease-out;
  transition-duration: .25s;
}

.fade-enter-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
  transition-delay: .25s;
}

.fade-enter, .fade-leave-active {
  opacity: 0
}

/*
  Enter and leave animations can use different
  durations and timing functions.
*/
.slide-fade-enter-active {
  transition: all 0.1s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.2s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}

.bounce-enter-active {
  animation: bounce-in .8s;
}
.bounce-leave-active {
  animation: bounce-in .5s reverse;
}
@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}

.nested-enter-active .inner,
.nested-leave-active .inner {
  transition: all 0.3s ease-in-out;
}

.nested-enter-from .inner,
.nested-leave-to .inner {
  transform: translateX(30px);
  opacity: 0;
}

.nested-enter-active .inner {
  transition-delay: 0.25s;
}

.slide-enter-active {
   -moz-transition-duration: 0.3s;
   -webkit-transition-duration: 0.3s;
   -o-transition-duration: 0.3s;
   transition-duration: 0.3s;
   -moz-transition-timing-function: ease-in;
   -webkit-transition-timing-function: ease-in;
   -o-transition-timing-function: ease-in;
   transition-timing-function: ease-in;
}

.slide-leave-active {
   -moz-transition-duration: 0.3s;
   -webkit-transition-duration: 0.3s;
   -o-transition-duration: 0.3s;
   transition-duration: 0.3s;
   -moz-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
   -webkit-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
   -o-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
   transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}

.slide-enter-to, .slide-leave {
   max-height: 100px;
   overflow: hidden;
}

.slide-enter, .slide-leave-to {
   overflow: hidden;
   max-height: 0;
}

.slide-fade-enter-active {
  overflow: hidden;
  transition: all 0.5s cubic-bezier(1, 0.5, 0.8, 1);
  -webkit-filter: blur(20px);
  -moz-filter: blur(20px);
  -o-filter: blur(20px);
  -ms-filter: blur(20px);
  filter: blur(20px);
 
}

.slide-fade-leave-active {
  overflow: hidden;
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
  transform: translateY(300px);
  opacity: 0;
  -webkit-filter: blur(20px);
  -moz-filter: blur(20px);
  -o-filter: blur(20px);
  -ms-filter: blur(20px);
  filter: blur(20px);

}

.slide-fade-enter-from,
.slide-fade-leave-to {
  overflow: hidden;
  transform: translateY(300px);
  opacity: 0;
  -webkit-filter: blur(20px);
  -moz-filter: blur(20px);
  -o-filter: blur(20px);
  -ms-filter: blur(20px);
  filter: blur(20px);
}

.hiddenoverflow {
  overflow: hidden;
}
</style>
