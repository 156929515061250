import { createRouter, createWebHistory } from 'vue-router'
// import { createRouter, createWebHashHistory } from 'vue-router'
import HomePage from '../views/HomePage'
import AboutPage from '../views/AboutPage'
import PortfolioPage from '../views/PortfolioPage'
import ContactPage from '../views/ContactPage'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomePage,
    meta: { transition: 'slide-left' },
  },
  {
    path: '/about',
    name: 'About',
    component: AboutPage,
    meta: { transition: 'slide-left' },
  },
  {
    path: '/portfolio',
    name: 'Portfolio',
    component: PortfolioPage,
    meta: { transition: 'slide-left' },
  },
  {
    path: '/contact',
    name: 'Contact',
    component: ContactPage,
    meta: { transition: 'slide-left' },
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL) || "/",
  // history: createWebHashHistory(),
  routes,
});

export default router
