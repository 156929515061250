<template>
	<!-- Footer -->
	<footer class="bg-light mt-auto thisfooter" >
		<!-- Copyright -->
		<div class="footer-copyright text-center py-3">
			&copy; Copyright <span id="copy_right_year"></span>
		</div>
		<!-- End copyright -->
	</footer>
</template>

<script>
export default {
    name: 'FooterItem',
    mounted() {
        const cyear = new Date().getFullYear();
        document.querySelector('#copy_right_year').innerHTML = cyear;
    }
}
</script>

<style>
    footer {
        position: fixed;
        bottom: 0;
        width: 100%;
    }
</style>